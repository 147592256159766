export const USER_DATA = 'userData';

// API URLS
export const BASE_API_URL = 'https://sophiaoffice.ddns.net/apidev';
export const BASE_URL = 'https://sophiaoffice.ddns.net/';

export const LOGIN_API_URL = '/athena/login';
export const TEACHERS_LIST_API_URL = '/athenaTeacher/teachers';
export const AVAILABLE_MENU_ITEMS_API_URL = '/AthenaGeneral/Menu';

export const CHANGE_TEACHER_STATUS_API_URL = '/AthenaTeacher/SetTeacherStatus';
export const DASHBOARD_OVERALL_DATA_API_URL = '/Athena/Tracking/OverallDashboard';
export const DASHBOARD_ATTENDANCE_PIE_URL = '/Athena/TimeTable/AttendanceChart';

export const STUDENTS_GROUPS_LIST_API_URL = '/athenaTeacher/StudentGroups';
export const TRACKING_GOALS_OR_OBJECTIVES_LIST_API_URL = '/athena/tracking/goalsNew';
export const GROUP_PROGRESS_EXTENDED_DATA_API_URL = '/Athena/Tracking/TrackingPercentsbySubjectNew';
export const FULL_TERMS_API_URL = '/AthenaGeneral/FullTerms';

export const GET_GROUP_REPORT_API_URL = '/AthenaStudent/GetCurriculum';
export const SAVE_GROUP_REPORT_API_URL = '/AthenaStudent/SaveGroupCurriculum';
export const SAVE_STUDENT_REPORT_API_URL = '/AthenaStudent/SaveIndividualDraftReport';
export const GET_STUDENT_REPORT_API_URL = '/AthenaStudent/GetIndividualDraftReports';
export const SUBMIT_STUDENT_REPORT_API_URL = '/AthenaStudent/SubmitIndividualDraftReport';

// Student groups
export const GET_STUDENT_GROUPS_API_URL = '/AthenaGroup/StudentGroups';
export const UPDATE_STUDENT_GROUP_API_URL = '/AthenaGroup/StudentGroup/Update';

export const GET_SUBJECTS_API_URL = '/Athena/Subject/all';
export const UPDATE_SUBJECT_API_URL = '/Athena/Subject/Update';
export const DELETE_SUBJECT_API_URL = '/Athena/Subject/DeleteSubjects';

export const GET_EMAIL_TEMPLATES_API_URL = '/EmailTemplatesList';
export const UPDATE_EMAIL_TEMPLATE_API_URL = '/EmailTemplatesUpdate';
export const DELETE_EMAIL_TEMPLATE_API_URL = '/Athena/Subject/DeleteSubjects';

export const ALL_SUBJECTS_API_URL = '/Athena/Subject/all';
export const ADD_GOAL_API_URL = '/Athena/AddGoal';
export const ADD_OBJECTIVE_API_URL = '/Athena/AddObjective';
export const ADD_GOAL_OBJECTIVE_API_URL = '/Athena/Tracking/AddGoalsObjectives';

export const DELETE_GOAL_OBJECTIVE_GROUP_API_URL = '/Athena/Tracking/DeleteGoalsObjectivesGroups';
export const DELETE_GOAL_API_URL = '/Athena/Tracking/DeleteGoal';
export const DELETE_OBJECTIVE_API_URL = '/Athena/Tracking/DeleteObjective';

export const IMPORT_GOALS_OBJECTIVES_API_URL = '/Athena/ImportGoalsObjectivesGroups';

export const TRACKING_TARGETS_LIST_API_URL = '/athena/tracking/GetTargets';
export const TRACKING_ASSESSED_LIST_API_URL = '/Athena/Tracking/AssessedTargets';

export const GET_TRACKINGS_LIST_API_URL = '/athena/tracking/GetTracking';
export const ADD_TRACKINGS_LIST_API_URL = '/Athena/Tracking/AddTracking';

export const GET_TEACHER_OBJECTIVES_LIST_API_URL = '/Athena/Tracking/GetTeacherObjective';
export const ADD_TEACHER_OBJECTIVES_API_URL = '/Athena/Tracking/AddTeacherObjective';
export const DELETE_TEACHER_OBJECTIVES_API_URL = '/Athena/Tracking/DeleteTeacherObjectivesWithTracking';
export const UPDATE_TEACHER_DATA_API_URL = '/AthenaTeacher/AddUpdateTeacherDetails';
export const GET_STUDENT_PROGRESS_REPORT_API_URL = '/Athena/Tracking/ProgressReport';
export const UPDATE_STUDENT_CAP4_API_URL = '/Athena/Tracking/ExtraReport';

export const GET_GOAL_PERCENTAGE_API_URL = '/Athena/Tracking/StudentTracking';

export const AVERAGE_ATTENDANCE_API_URL = '/Athena/DailyRegister/AverageAttendanceSchool';
export const REGISTER_STATES_ATTENDANCE_API_URL = '/Athena/DailyRegister/GetRegisterStates';
export const REGISTER_STATES_FROM_DATE_API_URL = '/Athena/DailyRegister/GetRegisterStatesFromDate';

export const ABSENT_ATTENDANCE_API_URL = '/Athena/DailyRegister/AbsentStudents';

export const FORM_REPORTS_SAVE_FORM_REPORT_API_URL = '/AthenaStudent/SaveFormReport';
export const FORM_REPORTS_SUBMIT_FORM_REPORT_API_URL = '/AthenaStudent/SubmitFormReport';
export const FORM_REPORTS_GET_FORM_REPORT_API_URL = '/AthenaStudent/getFormReports';
export const STUDENT_REPORTS_GET_STUDENT_REPORT_PREVIEW_DATA_API_URL = '/Athena/AthenaReport/PreviewReport';
export const STUDENT_REPORTS_GET_STUDENT_LIST_REPORTS_DATA_API_URL = 'Athena/AthenaReport/ListReports';
export const STUDENT_REPORTS_GET_STUDENT_LIST_FORM_REPORTS_DATA_API_URL = 'Athena/AthenaReport/ListFormReports';

export const GET_CANDIDATES_LIST_API_URL = '/Candidates/List';
export const GET_CANDIDATE_ITEM_API_URL = '/Candidates/Get';
export const CREATE_UPDATE_CANDIDATE_ITEM_API_URL = '/Candidates/Update';

export const GET_ATTENDANCES_LIST_API_URL = '/Athena/DailyRegister/GetAttendance';
export const UPDATE_ATTENDANCES_LIST_API_URL = '/Athena/DailyRegister/RegisterViaCode';

export const GET_USERS_LIST_API_URL = '/Users/List';
export const GET_USER_ITEM_API_URL = '/Users/Get';
export const CREATE_UPDATE_USER_ITEM_API_URL = '/Users/Update';

export const GET_QUERY_REPORTS_LIST_API_URL = '/Athena/AthenaReport/ListQueryReports';
export const GET_QUERY_REPORTS_DATA_API_URL = '/Athena/AthenaReport/ExecuteQueryReport';
export const GET_QUERY_REPORTS_XLS_PDF_API_URL = '/Athena/AthenaReport/ExportQueryReport';

export const GET_EFFORTS_API_URL = '/AthenaGeneral/Efforts';
export const GET_PERFORMANCES_API_URL = '/AthenaGeneral/Performances';

export const ADD_DIGITAL_NOTEBOOK_API_URL = '/Athena/Integration/AddEmbedded';
export const GET_DIGITAL_NOTEBOOKS_LIST_API_URL = '/Athena/Integration/ListEmbedded';
export const GET_DIGITAL_NOTEBOOK_API_URL = 'GET_DIGITAL_NOTEBOOK_API_URL';
export const CHANGE_DIGITAL_NOTEBOOK_STATUS_API_URL = '/Athena/Integration/StatusEmbedded';
export const GET_EVIDENCES_API_URL = '/Athena/Integration/EvidenceList';
export const ADD_EVIDENCES_API_URL = '/Athena/Integration/Evidence';
export const DELETE_EVIDENCES_API_URL = '/Athena/Integration/DeleteEvidence';

export const DELETE_DIGITAL_NOTEBOOK_API_URL = '/Athena/Integration/DeleteEmbedded';

// WEB URLS
export const STUDENTS_OBJECTIVE_TRACKER_TABLE_URL = '/teacher/student-objective-tracker-table/';
export const STUDENTS_TRACKER_TABLE_URL = '/teacher/student-tracker-table/';
export const TEACHER_MANAGEMENT = '/teacher-management/';
export const REPORTS_GENERATOR = '/reports-generator/';
export const DIGITAL_NOTEBOOK_URL = '/digital-notebook';

export const ADMISSIONS_URL = '/admissions';
export const STUDENT_GROUPS_MANAGEMENT_URL = '/student-group-management';

export const ADMISSIONS_ACTIONS_URL = '/admissions-actions';
export const TERM_DATES_URL = '/term-dates';
export const WEB_EDITOR_URL = '/web-editor';

// ROLES
export const USER_ROLES_TEACHER = 'T';
export const USER_ROLES_ADMIN = 'A';

// TRACKINGS
export const UNASSIGNED_TARGET_ID = 1;
export const NOT_MET_TARGET_ID = 2;
export const MET_TARGET_ID = 4;
export const ALMOST_TARGET_ID = 3;
export const EXCEEDING_TARGET_ID = 5;
export const MASTERY_TARGET_ID = 6;

export const UNASSIGNED_ASSESSES_ID = -1;
export const NOT_MET_ASSESSES_ID = 0;
export const MET_ASSESSES_ID = 1;

export const ADMISSIONS_LIST_PAGE_SIZE = 8;
export const STUDENTS_LIST_PAGE_SIZE = 1000;

export const TARGETS_SUBLIST_AVERAGE_STUDENTS_TARGET = 'ResultAverageTargetByGoal';
export const TARGETS_SUBLIST_GROUP_PROGRESS_FOR_ALL_GOALS = 'ResultPercForAllStudentsInGroup';
export const TARGETS_SUBLIST_STUDENTS_PROGRESS_FOR_ALL_GOALS = 'ResultPercInSubjectForStudent';
export const TARGETS_SUBLIST_SINGLE_GOAL_PROGRESS = 'ResultPercGoal';
export const TARGETS_SUBLIST_SUMMER = 'lstPercentsStudentGroupSummer';
export const TARGETS_SUBLIST_AUTUMN = 'ResultPercForAllStudentsInGroupAutumn';
export const TARGETS_SUBLIST_SPRING = 'lstPercentsStudentGroupSpring';

// Files upload/download
export const UPLOAD_FILE_API_URL = '/Athena/upload';
export const DOWNLOAD_FOLDER_API_URL = '/Athena/DownloadFolder';
export const GET_FOLDERS_DATA_API_URL = '/Athena/folders';
export const DELETE_FILE_API_URL = '/Athena/DeleteFile';
export const DELETE_FILE_FROM_URL_API_URL = '/Athena/DeleteFileFromURL';

export const COPY_FILES_LIST_API_URL = '/AthenaGeneral/CopyFiles';

export const ADMISSIONS_PARENTS_FOLDER_PLACE = 'Admissions\\Parents';
export const ADMISSIONS_STUDENTS_FOLDER_PLACE = 'Admissions\\Students';

export const GOAL_OBJECTIVE_TEMPLATE_FOLDER_NAME = 'Templates';

export const CURRENT_STUDENT_REPORTS_FORM_REPORTS_FOLDER_PLACE = 'Current Students\\Reports\\Form Reports';

// routes
export const STUDENT_TRACKER_TABLE_ROUTE_NAME = '/teacher/student-tracker-table/';
export const STUDENT_OBJECTIVE_TRACKER_TABLE_ROUTE_NAME = '/teacher/student-objective-tracker-table/';
export const DISABLED_BREADCRUMBS = [STUDENT_TRACKER_TABLE_ROUTE_NAME, STUDENT_OBJECTIVE_TRACKER_TABLE_ROUTE_NAME];

export const AUTUMN_TERM = ['0901', '1220'];
export const SPRING_TERM = ['0101', '0331'];
export const SUMMER_TERM = ['0415', '0710'];

export const GOALS_OBJECTIVES_GROUPS_API_URL = '/Athena/Tracking/GoalsObjectivesGroups';
export const INDEPENDENT_GOALS_API_URL = '/Athena/Tracking/GoalsIndependent';

export const ATTENDANCE_CODES_API_URL = '/Athena/Tracking/RegisterCodes';
export const UPDATE_TERM_DATES_API_URL = '/Athena/TermDates/Update';
export const DELETE_TERM_DATES_API_URL = '/Athena/TermDates/Delete';
export const GET_TERM_DATES_API_URL = '/Athena/TermDates';

export const ADMISSIONS_ACTIONS_CODES_API_URL = '/Athena/AdmissionsActions';
export const ADMISSIONS_ACTION_UPDATE_API_URL = '/Athena/Admissions/Actions/Update';
export const ADMISSIONS_ACTION_DELETE_API_URL = '/Athena/DeleteAdmissionActions';

export const ADMISSIONS_YEAR_GROUPS_API_URL = '/Athena/YearGroups';
export const ADMISSIONS_ACADEMIC_YEARS_API_URL = '/AthenaGeneral/AcademicYears';
export const TERMS_FOR_SPECIFIC_DATE_API_URL = '/AthenaGeneral/TermsFromDate';
export const ADMISSIONS_SCHOOL_TERMS_API_URL = '/AthenaGeneral/SchoolTerms';
export const ADMISSIONS_ADMIT_STUDENT_API_URL = '/Athena/Admissions/Students/Transfer';
export const ADMISSIONS_STUDENT_ADMIT_NOTE_TRANSFER = '/Athena/Admissions/Notes/Transfer';

export const STUDENT_NOTES_LIST = '/AthenaStudent/Students/Notes/List';
export const STUDENT_DOCUMENT_TYPES_API_URL = '/AthenaGeneral/DocumentTypes';
export const STUDENT_STATUSES_API_URL = '/AthenaStudent/StudentStatus';
export const STUDENT_ADD_NOTES_API_URL = '/AthenaStudent/Students/Notes';
export const STUDENT_DELETE_NOTES_API_URL = '/AthenaStudent/Students/Notes/Delete';

export const SUBJECTS_WITH_GROUPS_API_URL = '/AthenaTeacher/SubjectGroups';

export const AVERAGE_ATTENDANCE_TODAY_GRAPH_KEY = 'Today';
export const AVERAGE_ATTENDANCE_TODAY_PERCENTAGE_KEY = 'PercToday';
export const AVERAGE_ATTENDANCE_WEEK_PERCENTAGE_KEY = 'PercWeek';
export const AVERAGE_ATTENDANCE_TERM_PERCENTAGE_KEY = 'PercTerm';
export const AVERAGE_ATTENDANCE_TILL_DATE_PERCENTAGE_KEY = 'PercTillDate';

export const PRESENT_ATTENDANCE_GRAPH_KEY = 'PercPresence';
export const NOT_REPORTED_ATTENDANCE_GRAPH_KEY = 'PercNotReported';
export const LATE_ATTENDANCE_GRAPH_KEY = 'PercLate';
export const ABSENT_ATTENDANCE_GRAPH_KEY = 'PercAbsent';

export const SUBJECTS_GROUP_OTHERS = 'OTHERS';

export const DATE_FORMAT = 'YYYYMMDD';
export const DATE_FORMAT_SLASHED = 'YYYY/MM/DD';
export const DATE_FORMAT_ANT_DESIGN = 'yyyy/MM/dd';
export const DATE_FORMAT_ATTENDANCE = 'MMM dd, yyyy';
export const FORM_REPORT_DRAFT_STATUS = 'D';

// Select pickers relative

export const DIGITAL_NOTEBOOK_FILTER_DATASET = 'digital-notebook-filter';
export const DIGITAL_NOTEBOOK_ADD_NEW_DATASET = 'digital-notebook-add-new';
export const DASHBOARD_ATTAINMENT_DATASET = 'dashboard-temp';
export const ADD_TERM_DATE_DATASET = 'add-term-filter';
export const LATE_REGISTRATIONS_FILTER_DATASET = 'late-registrations-filter';

export const DIGITAL_NOTEBOOK_ASSIGN_EVIDENCE = 'digital-notebook-assign-evidence';

export const SELECT_PICKER_DATA_ID_ASSESSED = 'assessed';
export const SELECT_PICKER_DATA_ID_GOAL = 'goal';
export const SELECT_PICKER_DATA_ID_OBJECTIVE = 'objective';

export const SELECT_PICKER_DATA_ID_TEACHER = 'teacher';
export const SELECT_PICKER_DATA_ID_TERMS_OF_ENTRY = 'termsOfEntry';
export const SELECT_PICKER_DATA_ID_CURRICULUM_PHASE = ' curriculumPhase';

export const SELECT_PICKER_DATA_ID_SUBJECT = 'subject';
export const SELECT_PICKER_DATA_ID_STUDENT_GROUP = 'student-group';
export const SELECT_PICKER_DATA_ID_STUDENT = 'student';
export const SELECT_PICKER_DATA_ID_FULL_TERM = 'full-term';

// Statuses
export const DIGITAL_NOTEBOOK_STATUS_COMPLETED = 2;
export const DIGITAL_NOTEBOOK_STATUS_UNCOMPLETED = 1;
